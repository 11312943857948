// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "components/multipstepFormAndValidation.js"
import "init";

// components
import "components/AddressLookup";
import "components/BulletedList";
import "components/CalloutBlock";
import "components/CalloutBlocks";
import "components/Checkbox";
import "components/Checkboxes";
import "components/Confirmation";
import "components/CurrencyInput";
import "components/Details";
import "components/DonateBlock";
import "components/Error";
import "components/ErrorSummary";
import "components/Field";
import "components/Fieldset";
import "components/Form";
import "components/Footer";
import "components/Giftaid";
import "components/GiftaidRegular";
import "components/Header";
import "components/Heading";
import "components/Hero";
import "components/HeroImage";
import "components/HeroImageSplit";
import "components/Hint";
import "components/Image";
import "components/ImageAndTextSection";
import "components/ImpactBlock";
import "components/Label";
import "components/Layout";
import "components/Lede";
import "components/Link";
import "components/Main";
import "components/NextPrevButtons";
import "components/Paragraph";
import "components/Section";
import "components/SectionCenter";
import "components/SectionFlex";
import "components/Sections";
import "components/SelectBox";
import "components/SelectBoxRelations";
import "components/SkipLink";
import "components/SplitSection";
import "components/StepTrackerForm";
import "components/StepTrackerThanksPage";
import "components/Submit";
import "components/Switch";
import "components/Switches";
import "components/Table";
import "components/Teaser";
import "components/Teasers";
import "components/TextArea";
import "components/TextInput";
import "components/Title";
import "components/Trigger";
import "components/Video";

// images
import "../images/favicon.ico";
import "../images/donate-hero.jpg";
import "../images/barnardos-stat.jpg";
import "../images/Tom-volunteering-in-shop.jpg";
import "../images/thank-you.jpg";
import "../images/thank-you-hero.jpg";
import "../images/gift-teaser.jpg";
import "../images/news-teaser.jpg";
import "../images/get-involved-teaser.jpg";
import "../images/opayo-logo.jpg";
import "../images/secured-by-opayo-logo.jpg";
import "../images/gocardless.png";
import "../images/dd-logo.jpg";
import "../images/donate-hero-mobile.jpg";
import "../images/donate-any.jpg";
import "../images/donate5-10.jpg";
import "../images/donate18-100.jpg";
import "../images/donate10-25.jpg";
import "../images/donate25-50.jpg";
import "../images/signature.jpg";
import "../images/woman-and-boy-on-a-tablet.jpg";


const consentBanner = require('@barnardoswebteam/consent-banner');
consentBanner();

import Rails from "@rails/ujs";
if (Rails.fire(document, "rails:attachBindings")) {
  Rails.start();
}